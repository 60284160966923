
/* Account.css */

.text-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 40%;
}

.account {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 1em;
  font-size: 1.25em;
  font-weight: bold;
}
@media (width <= 48em) {
  .pass-text {
    display: none;
  }
  .text-column {
    width: 100%;
  }

  .account {
    flex-direction: column;
  }
}