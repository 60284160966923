ul {
  --icon-space: 2.3em;
  list-style: none;
  padding: 0;
}

li.one {
  padding-left: var(--icon-space);
}

li.one:before {
  display: inline-block;
  margin-left: calc( var(--icon-space) * -1 );
  width: var(--icon-space);
}

@import